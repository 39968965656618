import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { IResponse } from '@fgp/shared/interfaces';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { ISuscripcionContacto } from '@fgp/subscribe/interface';
import { BehaviorSubject, Observable } from 'rxjs';

const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  localStorageService: Storage;
  SHOW_NEWSLETTER: string;
  isMostrarNewsletter = new BehaviorSubject<boolean>(false);

  private urlClienteOnline: string;

  constructor(private http: HttpClient, private sessionService: AdminSessionService, @Inject(APP_CONFIG) private appConfig) {
    this.urlClienteOnline = this.appConfig.apiPublicUrl + 'online/suscripcion';
  }

  setShowNewsletter(show: boolean): void {
    this.isMostrarNewsletter.next(show);
  }

  isMostrarSubscribe(): Observable<boolean> {
    return this.isMostrarNewsletter.asObservable();
  }

  doSubscribe(suscripcionDTO: ISuscripcionContacto): Observable<ISuscripcionContacto> {
    return this.http.post<ISuscripcionContacto>(this.urlClienteOnline, suscripcionDTO);
  }

  removeSubscribe(token: string): Observable<IResponse> {
    return this.http.delete<IResponse>(this.urlClienteOnline.concat('/').concat(token), { headers });
  }

  public isSuscritoANewsletter(): boolean {
    return this.sessionService.isNewsletter();
  }
}
